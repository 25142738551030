* {
  box-sizing: border-box;
  font-family: "Inter", serif;
}

body,
#root {
  background-color: #131313;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

#root {
  color: white;
}

p {
  margin: 0;
}
